import React, { useContext } from "react";

import server from "./server";

const ServerInfoContext = React.createContext({});

const ServerInfoManager = ({ children }) => {
  return (
    <ServerInfoContext.Provider value={server}>
      {typeof children === "function" ? children(server) : children}
    </ServerInfoContext.Provider>
  );
};

const useServerInfo = () => useContext(ServerInfoContext);

const ServerInfoConsumer = ServerInfoContext.Consumer;

export { ServerInfoManager, useServerInfo, ServerInfoConsumer };
