import React, { Fragment } from "react";
import { useToggle } from "worklete/hooks";

import { Button, AdminLink } from "Sections/WorkleteAdmin/components";

const wrapWith = (TriggerComponent) => (ModalComponent) => {
  return (props) => {
    const { triggerProps, disabled, ...rest } = props;

    const { title, triggerLabel } = props;

    const label = triggerLabel || title;

    const [is_open, onOpen, onClose] = useToggle();

    return (
      <Fragment>
        <TriggerComponent
          {...triggerProps}
          disabled={disabled}
          onClick={onOpen}
        >
          {label}
        </TriggerComponent>
        {is_open && (
          <ModalComponent
            is_open={is_open}
            onClose={onClose}
            triggerLabel={triggerLabel}
            {...rest}
          />
        )}
      </Fragment>
    );
  };
};

const wrapWithLink = wrapWith(AdminLink);

const wrapWithButton = wrapWith(Button);

export { wrapWithLink, wrapWithButton };

export default wrapWith;
