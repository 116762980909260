import _ from "lodash";
import React from "react";
import "./Stack.scss";
import PropTypes from "prop-types";
import classNames from "classnames";

const { bool, any } = PropTypes;

const propTypes = {
  itemPadding: any,
  padding: any,
  divider: bool,
};

const defaultProps = {
  itemPadding: 8,
  padding: 0,
};

const Stack = ({ children, itemPadding, padding, divider }) => {
  const items = _.compact(React.Children.toArray(children));

  const displayItems = _.map(items, (child, index) => {
    return (
      <div
        key={index}
        className={classNames("admin-stack--item", { "has-divider": divider })}
        style={{ padding: itemPadding }}
      >
        {child}
      </div>
    );
  });

  return (
    <div className="admin-stack" style={{ padding }}>
      {displayItems}
    </div>
  );
};

Stack.propTypes = propTypes;
Stack.defaultProps = defaultProps;

export default Stack;
