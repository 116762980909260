import _ from "lodash";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./LayoutPanels.css";

const { any } = PropTypes;

const Header = ({ children, borderColor }) => {
  if (!children) return null;

  return (
    <div
      className="layout-panels--header"
      style={{ borderBottomColor: borderColor }}
    >
      {children}
    </div>
  );
};
Header.displayName = "LayoutPanels.Header";
Header.propTypes = { borderColor: any };

const Footer = ({ children, borderColor }) => {
  if (!children) return null;

  return (
    <div
      className="layout-panels--footer"
      style={{ borderTopColor: borderColor }}
    >
      {children}
    </div>
  );
};
Footer.displayName = "LayoutPanels.Footer";
Footer.propTypes = { borderColor: any };

const LeftPanel = ({ width, borderColor, children }) => {
  if (!children) return null;

  return (
    <div
      className="layout-panels--left-panel"
      style={{ width, borderRightColor: borderColor }}
    >
      {children}
    </div>
  );
};

LeftPanel.displayName = "LayoutPanels.LeftPanel";

const RightPanel = ({ width, borderColor, children }) => {
  if (!children) return null;

  return (
    <div
      className="layout-panels--right-panel"
      style={{ width, borderLeftColor: borderColor }}
    >
      {children}
    </div>
  );
};

RightPanel.displayName = "LayoutPanels.RightPanel";

const Main = ({ children }) => <Fragment>{children}</Fragment>;

Main.displayName = "LayoutPanels.Main";

const LayoutPanels = (props) => {
  const {
    children,
    header,
    footer,
    leftPanel,
    rightPanel,
    leftPanelWidth,
    rightPanelWidth,
  } = props;

  const kiddos = React.Children.toArray(children);

  const elements = _.groupBy(kiddos, ({ type } = {}) => {
    switch (type) {
      case LeftPanel:
        return "leftPanels";
      case RightPanel:
        return "rightPanels";
      case Header:
        return "headers";
      case Footer:
        return "footers";
      default:
        return "mains";
    }
  });

  const { leftPanels, rightPanels, headers, footers, mains } = elements;

  return (
    <div className="layout-panels">
      {header && <Header>{header}</Header>}
      {headers}
      <div className="layout-panels--wrap">
        {leftPanel && <LeftPanel width={leftPanelWidth}>{leftPanel}</LeftPanel>}
        {leftPanels}

        <div className="layout-panels--mains">{mains}</div>

        {rightPanels}
        {rightPanel && (
          <RightPanel width={rightPanelWidth}>{rightPanel}</RightPanel>
        )}
      </div>

      {footers}
      {footer && <Footer>{footer}</Footer>}
    </div>
  );
};

LayoutPanels.LeftPanel = LeftPanel;
LayoutPanels.RightPanel = RightPanel;
LayoutPanels.Main = Main;
LayoutPanels.Header = Header;
LayoutPanels.Footer = Footer;

export { Header, Footer, Main, LeftPanel, RightPanel };

export default LayoutPanels;
