import _ from "lodash";
import React from "react";
import "./LinkToApp.scss";

import { app_origin } from "App/System/server/server";

const base = app_origin || "http://ops-not-configured-correctly.worklete.com";

const LinkToApp = ({ to, children, organization_id, ...rest }) => {
  const base_to = to || "/";

  const full_to = organization_id
    ? base_to +
      (_.includes(base_to, "?") ? "&" : "?") +
      `organization_id=${organization_id}`
    : base_to;

  return (
    <a
      {...rest}
      href={base + full_to}
      className="admin-link-to-app"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children || to}
    </a>
  );
};

export default LinkToApp;
