import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import AuthenticatedRoutes from "./Routes/AuthenticatedRoutes";
import UnauthenticatedRoutes, {
  unauthenticated_routes,
} from "./Routes/UnauthenticatedRoutes";
import AmbivalentRoutes, { ambivalent_routes } from "./Routes/AmbivalentRoutes";
import { Placeholder } from "worklete/components";
import { useSession, useUser } from "worklete/hooks";

import ResetPasswordController from "./AuthWorkflow/ResetPassword/ResetPasswordController";

const AppRoutes = () => {
  const { signed_in, user_id } = useSession();
  const { username, email, requires_new_password } = useUser();
  const [needsReset, setNeedsReset] = useState(requires_new_password);

  useEffect(() => {
    if (requires_new_password) {
      setNeedsReset(true);
    }
  }, [requires_new_password]);

  if (needsReset) {
    return (
      <ResetPasswordController
        user_id={user_id}
        username={username}
        email={email}
        onFlowComplete={() => setNeedsReset(false)}
      />
    );
  }

  return (
    <Switch>
      <Route path={ambivalent_routes}>
        <AmbivalentRoutes />
      </Route>

      <Route path={unauthenticated_routes}>
        {signed_in ? <Redirect to="/training" /> : <UnauthenticatedRoutes />}
      </Route>

      <Route>
        {!signed_in ? (
          <Redirect to="/auth/login" />
        ) : user_id ? (
          <AuthenticatedRoutes />
        ) : (
          <div style={{ textAlign: "center", padding: "100px 20px" }}>
            <Placeholder message="Loading..." />
          </div>
        )}
      </Route>
    </Switch>
  );
};

export default AppRoutes;
