import _ from "lodash";
import React from "react";
import JsonView from "react-json-view";

const Json = ({ name, title, json, ...rest }) => {
  return (
    <div style={{ padding: 8, backgroundColor: "#FFF" }}>
      {!_.isObject(json) ? (
        "" + json
      ) : (
        <JsonView
          src={json}
          name={name || title || false}
          indentWidth={2}
          collapsed={3}
          displayDataTypes={false}
          enableClipboard={false}
          collapseStringsAfterLength={50}
          {...rest}
        />
      )}
    </div>
  );
};

export default Json;
