import _ from "lodash";
import React from "react";
import Json from "./Json";
import MiniTable from "../MiniTable/MiniTable";
import JsonValue from "./JsonValue";

const isId = (str = "") => str === "id" || str.slice(-3) === "_id";

const isDate = (str) => str.slice(-3) === "_at";

const getType = (key) => (isId(key) ? "id" : isDate(key) ? "date" : undefined);

const JsonTable = ({ json, keys, title, ...rest }) => {
  if (_.isArray(json) && _.size(json) === 0) {
    return (
      <MiniTable {...rest} title={title}>
        <MiniTable.Row>
          <div style={{ textAlign: "center", padding: 20, color: "#888" }}>
            Empty List
          </div>
        </MiniTable.Row>
      </MiniTable>
    );
  } else if (_.isArray(json) && _.isObject(_.first(json))) {
    const _keys = keys || _.keys(_.first(json));

    const rows = _.map(json, (row, key) => {
      const cells = _.map(_keys, (key) => {
        const type = getType(key);
        return (
          <MiniTable.Cell key={key}>
            <JsonValue type={type} value={row[key]} />
          </MiniTable.Cell>
        );
      });

      return <MiniTable.Row key={`${row.id}-${key}`}>{cells}</MiniTable.Row>;
    });

    return (
      <MiniTable title={title} columns={_keys}>
        {rows}
      </MiniTable>
    );
  } else if (_.isObject(json)) {
    const rows = _.map(json, (val, key) => {
      const type = getType(key);

      return (
        <MiniTable.Row key={key}>
          <JsonValue type="text" value={key} />
          <JsonValue type={type} value={val} />
        </MiniTable.Row>
      );
    });

    return (
      <MiniTable {...rest} title={title}>
        {rows}
      </MiniTable>
    );
  } else {
    return <Json value={json} />;
  }
};

export default JsonTable;
