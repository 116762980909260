import _ from "lodash";
import React from "react";
import classNames from "classnames";
import "./SelectList.scss";

const SelectList = ({ value, onChange, items }) => {
  const _items = _.compact(
    _.map(items, (item) => {
      return _.isString(item)
        ? { value: item }
        : item && item.value
        ? item
        : null;
    }),
  );
  const displayItems = _.map(_items, (item, index) => {
    const selected = item.value === value;
    const handleChange = () => !item.disabled && onChange(item.value);

    return (
      <div
        key={item.value}
        className={classNames("admin-select-list--item", {
          "is-selected": selected,
          "is-disabled": item.disabled,
        })}
        onClick={handleChange}
      >
        <input
          className="admin-select-list--radio-button"
          type="radio"
          name={item.value}
          value={item.value}
          checked={selected}
          disabled={item.disabled}
          onChange={handleChange}
        />

        <span
          className={classNames("admin-select-list--item-label", {
            "is-disabled": item.disabled,
          })}
        >
          <div className="admin-select-list--item-label-text">
            {item.label || item.value}
          </div>
          {item.description && (
            <div className="admin-select-list--item-description">
              {item.description}
            </div>
          )}
        </span>
      </div>
    );
  });

  return <div className="admin-select-list">{displayItems}</div>;
};

export default SelectList;
