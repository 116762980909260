import _ from "lodash";
import React from "react";
import classNames from "classnames";
import "./SelectList.scss";

const MultiSelectList = ({ value, onChange, items, compact }) => {
  const _items = _.compact(
    _.map(items, (item) => {
      return _.isString(item)
        ? { value: item }
        : item && item.value
        ? item
        : null;
    }),
  );

  const displayItems = _.map(_items, (item, index) => {
    const selected = _.includes(value, item.value);

    const handleChange = () => {
      if (!item.disabled) {
        if (selected) {
          onChange(_.without(value, item.value));
        } else {
          onChange([...value, item.value]);
        }
      }
    };

    return (
      <div
        key={item.value}
        className={classNames("admin-select-list--item", {
          "is-selected": selected,
          "is-disabled": item.disabled,
          "is-compact": compact,
        })}
        onClick={handleChange}
        onKeyPress={(e) => {
          if (e.key === "Enter") handleChange();
        }}
      >
        <input
          className="admin-select-list--checkbox"
          type="checkbox"
          name={item.value}
          value={item.value}
          checked={selected}
          disabled={item.disabled}
          onChange={handleChange}
        />

        <span
          className={classNames("admin-select-list--item-label", {
            "is-disabled": item.disabled,
          })}
        >
          <div className="admin-select-list--item-label-text">
            {item.label || item.value}
          </div>
          {item.description && (
            <div className="admin-select-list--item-description">
              {item.description}
            </div>
          )}
        </span>
        {item.badge && (
          <span
            className={classNames("admin-select-list--item-badge", {
              "is-disabled": item.disabled,
            })}
          >
            {item.badge}
          </span>
        )}
      </div>
    );
  });

  return <div className="admin-select-list">{displayItems}</div>;
};

export default MultiSelectList;
