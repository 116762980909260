import React from "react";
import { Route, Switch, Redirect } from "react-router";
import SignUpController from "../AuthWorkflow/SignUp/SignUpController";
import SignoutController from "../AuthWorkflow/SignOut/SignoutController";
import SignInMasqueradeController from "../AuthWorkflow/SignIn/SignInMasqueradeController";
import { DebugRoutes } from "Sections/Sections";
import LoginController from "Sections/Auth/Login/LoginController";

const signout_aliases = ["/logout", "/singout"];

const ambivalent_routes = [
  "/debug",
  "/signout",
  "/signup",
  "/singup",
  "/masquerade/:user_id/:token",
  "/auth/login",
  "/login",
].concat(signout_aliases);

const AmbivalentRoutes = () => {
  return (
    <Switch>
      <Route path="/auth/login">
        <LoginController />
      </Route>
      <Route path="/debug">
        <DebugRoutes />
      </Route>

      {/* SignInMasqueradeController is ambivalent */}
      {/* because it handles telling you that you're currently signed in */}
      {/*rather than relying on the auto-redirect*/}
      <Route
        path="/masquerade/:user_id/:token"
        render={({ match }) => (
          <SignInMasqueradeController
            user_id={match.params.user_id}
            token={decodeURIComponent(match.params.token)}
          />
        )}
      />

      {/* SignUpController is ambivalent */}
      {/* because it allows you to start out not-signed-in */}
      {/* and eventually become signed-in through the process,*/}
      {/* but wants you to click into the app yourself from the outro-screen*/}
      <Route path="/signup">
        <SignUpController />
      </Route>

      <Redirect from="/singup" to="/signup" />

      <Route path="/signout">
        <SignoutController />
      </Route>

      <Route path={signout_aliases}>
        <Redirect to="/signout" />
      </Route>

      <Route path="/login">
        <Redirect to="/auth/login" />
      </Route>

      <Redirect to="/training" />
    </Switch>
  );
};
export { ambivalent_routes };

export default AmbivalentRoutes;
