import JsonValue from "./JsonValue";
import JsonTable from "./JsonTable";
import Json from "./Json";

Json.Value = JsonValue;
Json.Table = JsonTable;

export { JsonTable, JsonValue };

export default Json;
