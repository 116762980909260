import _ from "lodash";
import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useReducer,
} from "react";
import { NavLink } from "react-router-dom";
import "./Breadcrum.scss";

const CrumbsContext = createContext({});
CrumbsContext.displayName = "CrumbsContext";

const AddBreadcrumbContext = createContext(() => {});
AddBreadcrumbContext.displayName = "AddBreadcrumbContext";

const extractName = (to = "") => _.last(to.split("?")[0].split("/"));

const getLength = (to = "") => _.size(to.split("?")[0].split("/"));

const reducer = (state, action) => {
  const { type, id, crumb } = action;
  switch (type) {
    case "add":
      return _.extend({}, state, { [id]: crumb });
    case "remove":
      return _.omit(state, id);
    default:
      return state;
  }
};

const Manager = (props) => {
  const { children } = props;

  const [crumbs, dispatch] = useReducer(reducer, {});

  return (
    <CrumbsContext.Provider value={crumbs}>
      <AddBreadcrumbContext.Provider value={dispatch}>
        {children}
      </AddBreadcrumbContext.Provider>
    </CrumbsContext.Provider>
  );
};

const List = () => {
  const crumbs = useContext(CrumbsContext);

  const items = _.map(_.values(crumbs), (crumb) => {
    return { ...crumb, length: getLength(crumb.to) };
  });

  return (
    <div className="breadcrumb--breadcrumb-list">
      {_.map(_.sortBy(items, "length"), ({ to, label, depth }, index) => {
        return (
          <React.Fragment key={to}>
            <NavLink to={to || "/"} className="breadcrumb--breadcrumb-link">
              {label || extractName(to)}
            </NavLink>
            {index < items.length - 1 && (
              <span className="breadcrumb--breadcrumb-link-divider">/</span>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const useBreadcrumb = ({ to, label, loading }) => {
  const id = useMemo(() => _.uniqueId("crumb-"), [to, label]); // eslint-disable-line
  const dispatch = useContext(AddBreadcrumbContext);

  useEffect(() => {
    const _label = label || (loading ? "Loading..." : "Error");
    dispatch({ type: "add", id, crumb: { to, label: _label } });
    return () => dispatch({ type: "remove", id });
  }, [id, to, label, loading, dispatch]);
};

const Breadcrumb = ({ to, label, loading, children }) => {
  useBreadcrumb({ to, label, loading });
  return children || null;
};

Breadcrumb.Manager = Manager;
Breadcrumb.List = List;
Breadcrumb.useBreadcrumb = useBreadcrumb;

export { Manager, List, useBreadcrumb, Breadcrumb };

export default Breadcrumb;
