import React, { useState } from "react";
import { Dialog } from "@reach/dialog";
import LayoutPanels from "../LayoutPanels/LayoutPanels";
import LayoutBar from "../LayoutBar/LayoutBar";
import AdminButton from "../AdminButton/AdminButton";
import PropTypes from "prop-types";

import "@reach/dialog/styles.css";
import "./AdminModal.scss";

const { any, bool } = PropTypes;

const propTypes = {
  submitLabel: any,
  submitDisabled: bool,
};

const defaultProps = {
  cancelLabel: "Cancel",
  submitLabel: "Submit",
};

const AdminModal = (props) => {
  const {
    is_open,
    onClose,
    title,
    submitLabel,
    submitDisabled,
    onSubmit,
    onCancel,
    children,
    height,
    width,
  } = props;

  const handleCancel = onCancel || onClose;

  const [working, setWorking] = useState(false);

  const handleSubmit = () => {
    const promise = onSubmit();
    if (promise && promise.then) {
      setWorking(true);
      promise.then(() => setWorking(false)).catch(() => setWorking(false));
    }
  };

  const header = (
    <LayoutBar
      left={<span className="admin-modal--title">{title}</span>}
      right={<AdminButton onClick={onClose}>X</AdminButton>}
    />
  );

  const footer = (
    <LayoutBar
      right={
        <LayoutBar.Section>
          <AdminButton onClick={handleCancel}>Cancel</AdminButton>
          <AdminButton
            primary
            disabled={working || submitDisabled}
            onClick={handleSubmit}
          >
            {submitLabel}
          </AdminButton>
        </LayoutBar.Section>
      }
    />
  );

  return is_open ? (
    <Dialog className="admin-modal" isOpen={is_open} style={{ height, width }}>
      <LayoutPanels header={header} footer={footer}>
        {children}
      </LayoutPanels>
    </Dialog>
  ) : null;
};

AdminModal.propTypes = propTypes;
AdminModal.defaultProps = defaultProps;

export default AdminModal;
