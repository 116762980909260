import _ from "lodash";

import { env, api_origin } from "App/System/server/server";

const AUTH_KEY = "worklete-auth:" + api_origin;

const ORG_KEY = "worklete-org:" + api_origin;

const inititial_headers = (() => {
  try {
    return JSON.parse(localStorage.getItem(AUTH_KEY));
  } catch (e) {
    return {};
  }
})();

const loadHeaders = () => inititial_headers;

const initial_oid = ((headers = {}) => {
  try {
    if (headers["access-token"]) {
      return localStorage.getItem(ORG_KEY);
    } else {
      localStorage.removeItem(ORG_KEY);
      return null;
    }
  } catch (e) {
    return null;
  }
})(inititial_headers);

const loadOrganizationId = () => initial_oid;

const saveHeaders = (headers) => {
  try {
    if (headers["access-token"]) {
      const value = _.pick(headers, ["access-token", "uid", "client"]);
      localStorage.setItem(AUTH_KEY, JSON.stringify(value));
    } else {
      localStorage.removeItem(AUTH_KEY);
      localStorage.removeItem(ORG_KEY);
    }
  } catch (err) {
    console.error(err);
  }
};

const saveOrganizationId = (organization_id) => {
  try {
    if (organization_id) {
      localStorage.setItem(ORG_KEY, organization_id);
    } else {
      localStorage.removeItem(ORG_KEY);
    }
  } catch (err) {
    console.error(err);
  }
};

export { loadHeaders, saveHeaders, loadOrganizationId, saveOrganizationId };
