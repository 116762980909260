import React from "react";
import AdminInput from "../AdminInput/AdminInput";
import str from "underscore.string";
import "./AdminSimpleEditModalItem.scss";

import { useAdminSimpleModal } from "./AdminSimpleEditModalContext";

const humanize = (key) => str.titleize(str.humanize(key));

const AdminSimpleEditModalItem = (props) => {
  const { propertyKey, label, note, placeholder } = props;

  const { value, onChange } = useAdminSimpleModal(propertyKey);

  const _placeholder = placeholder || label || propertyKey;
  const _label = label || humanize(propertyKey);

  return (
    <div className="admin-simple-edit-item">
      <label className="admin-simple-edit-item--label">{_label}</label>
      <AdminInput
        value={value}
        onChange={onChange}
        placeholder={_placeholder}
      />
      {note && <span className="admin-simple-edit-item--note">{note}</span>}
    </div>
  );
};

export default AdminSimpleEditModalItem;
