import _ from "lodash";
import React from "react";

const UUID = ({ id, children, max_display_length, style, ...rest }) => {
  const value = id || (typeof children === "string" && children) || "";

  const truncated =
    max_display_length && _.size(value) > max_display_length
      ? `${value.slice(0, 10)}...${value.slice(-10)}`
      : value;

  return (
    <span style={{ fontFamily: "monospace", ...style }} title={id} {...rest}>
      {truncated}
    </span>
  );
};

export default UUID;
