import _ from "lodash";
import React, { Children } from "react";
import Markdown from "react-markdown";
import "github-markdown-css/github-markdown.css";
import "./AdminProse.scss";

const clean = (str = "") => {
  const lines = str.split("\n");
  const non_empty_lines = _.compact(lines);
  const trimmed_lines = _.map(non_empty_lines, (line) => line.trim());
  return trimmed_lines.join("\n");
};

const combineReducer = (answer, item) => {
  if (!_.isString(item)) {
    return [...answer, item];
  } else {
    const last = _.last(answer);
    if (!_.isString(last)) {
      return [...answer, item];
    } else {
      const start = _.initial(answer);
      return [...start, [last, item].join("\n")];
    }
  }
};

const combine = (list) => _.reduce(list, combineReducer, []);

const AdminProse = ({ markdown = "", children, ...rest }) => {
  const items = children ? Children.toArray(children) : [markdown];
  const combined_items = combine(items);

  return (
    <div {...rest}>
      {_.map(combined_items, (child) => {
        if (_.isString(child)) {
          const source = clean(child);
          return (
            <Markdown
              key={source}
              className="admin-prose markdown-body"
              source={source}
            />
          );
        } else {
          return child;
        }
      })}
    </div>
  );
};

export default AdminProse;
