const is_development = process.env.NODE_ENV !== "production";

const api_origin = process.env.REACT_APP_SERVER_ORIGIN;

const env_code = process.env.REACT_APP_ENV_CODE || "UNKNOWN";

const app_origin = process.env.REACT_APP_APP_ORIGIN || "";

const server = {
  app_origin,
  env: env_code,
  env_code,
  api_origin,
  is_development,
};

export { env_code as env, env_code, app_origin, api_origin, is_development };

export default server;
