import _ from "lodash";
import React from "react";
import LoadingContainer from "../Loading/Container/LoadingContainer";
import Message from "../Message/Message";
import "./FixedTable.css";

const wrapItem = (item, Class, index) => {
  return item && item.type === Class ? (
    item
  ) : (
    <Class key={`fixed-table-${Class.type}-${item}-${index}`}>{item}</Class>
  );
};

const wrapList = (children, Class) =>
  React.Children.map(children, (cell, index) => wrapItem(cell, Class));

const Cell = ({ children }) => (
  <td className="fixed-table--body-cell">{children}</td>
);

const Row = ({ children }) => (
  <tr className="fixed-table--body-row">{wrapList(children, Cell)}</tr>
);

const HeaderCell = ({ children, ...rest }) => (
  <th {...rest} className="fixed-table--header-cell">
    {children}
    <div className="fixed-table--header-cell-border" />
  </th>
);

const HeaderRow = ({ children }) => (
  <thead className="fixed-table--header">
    <tr className="fixed-table--header-row">
      {wrapList(children, HeaderCell)}
    </tr>
  </thead>
);

const FixedTable = (props) => {
  const { loading, error, columns = [], headerRow, children = [] } = props;

  const header = headerRow || (
    <HeaderRow>{wrapList(columns, HeaderCell)}</HeaderRow>
  );

  const contents = !_.isEmpty(children)
    ? wrapList(children, Row)
    : !loading &&
      !error && (
        <tr>
          <td colSpan={_.size(columns) || 100}>
            <Message icon="flag" message="No Results" />
          </td>
        </tr>
      );

  return (
    <div className="fixed-table">
      <LoadingContainer loading={loading} error={error}>
        <table className="fixed-table--table">
          {header}
          <tbody className="fixed-table--body">{contents}</tbody>
        </table>
      </LoadingContainer>
    </div>
  );
};

FixedTable.HeaderRow = HeaderRow;
FixedTable.HeaderCell = HeaderCell;
FixedTable.Cell = Cell;
FixedTable.Row = Row;

export { Row, Cell, HeaderCell };

export default FixedTable;
