import React, { createContext, useContext } from "react";

const AdminSimpleEditModalValueContext = createContext({});
const AdminSimpleEditModalOnChangeContext = createContext(() => {});

const AdminSimpleEditModalProvider = ({ value, onChange, children }) => {
  return (
    <AdminSimpleEditModalValueContext.Provider value={value}>
      <AdminSimpleEditModalOnChangeContext.Provider value={onChange}>
        {children}
      </AdminSimpleEditModalOnChangeContext.Provider>
    </AdminSimpleEditModalValueContext.Provider>
  );
};

const useAdminSimpleModal = (key) => {
  const _value = useContext(AdminSimpleEditModalValueContext) || {};
  const _onChange = useContext(AdminSimpleEditModalOnChangeContext);

  const value = _value[key];
  const onChange = (val) => _onChange({ ..._value, [key]: val });

  return { value, onChange };
};

export { AdminSimpleEditModalProvider, useAdminSimpleModal };
