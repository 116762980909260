import React from "react";
import str from "underscore.string";
import "./AdminSimpleEditModalItem.scss";
import { useAdminSimpleModal } from "./AdminSimpleEditModalContext";
import AdminSelect from "../AdminSelect/AdminSelect";

const humanize = (key) => str.titleize(str.humanize(key));

const AdminSimpleEditModalBoolean = (props) => {
  const { propertyKey, label, note } = props;

  const { value, onChange } = useAdminSimpleModal(propertyKey);

  const _label = label || humanize(propertyKey);

  const val = value === true ? "True" : value === false ? "False" : value;

  const handleChange = (value) => {
    const converted_value =
      value === "True" ? true : value === "False" ? false : value;

    onChange(converted_value);
  };

  return (
    <div className="admin-simple-edit-item">
      <label className="admin-simple-edit-item--label">{_label}</label>
      <AdminSelect
        value={val}
        options={["True", "False"]}
        onChange={handleChange}
      />
      {note && <span className="admin-simple-edit-item--note">{note}</span>}
    </div>
  );
};

export default AdminSimpleEditModalBoolean;
