import _ from "lodash";
import React, { createContext, useContext, useMemo } from "react";
import classNames from "classnames";
import "./MiniTable.scss";

const MiniTableContext = createContext({
  compact: false,
  classes: (cl) => cl,
});

const wrapItem = (item, Class, index) => {
  return item && item.type === Class ? (
    item
  ) : (
    <Class key={`mini-table-${Class.type}-${item}-${index}`}>{item}</Class>
  );
};

const wrapList = (children, Class) =>
  React.Children.map(children, (cell, index) => wrapItem(cell, Class));

const Cell = ({ children }) => {
  const { classes } = useContext(MiniTableContext);
  return <td className={classes("mini-table--body-cell")}>{children}</td>;
};

const Row = ({ children }) => {
  return <tr className="mini-table--body-row">{wrapList(children, Cell)}</tr>;
};

const HeaderCell = ({ children }) => {
  const { classes } = useContext(MiniTableContext);
  return (
    <th className={classes("mini-table--header-cell", !children && "is-empty")}>
      {children}
    </th>
  );
};

const HeaderRow = ({ children }) => (
  <thead className="mini-table--header">
    <tr className="mini-table--header-row">{wrapList(children, HeaderCell)}</tr>
  </thead>
);

const MiniTable = (props) => {
  const {
    columns = [],
    headerRow,
    children = [],
    title,
    message,
    compact,
    bare,
  } = props;

  const header =
    headerRow ||
    (!_.isEmpty(columns) && (
      <HeaderRow>
        {_.map(columns, (column, i) => {
          if (_.isString(column)) {
            return <HeaderCell key={`header-${i}`}>{column}</HeaderCell>;
          } else if (_.isObject(column)) {
            const { label, width } = column;
            return <HeaderCell width={width}>{label}</HeaderCell>;
          } else {
            return <HeaderCell />;
          }
        })}
      </HeaderRow>
    ));

  const contents = !_.isEmpty(children) ? (
    wrapList(children, Row)
  ) : (
    <tr>
      <td colSpan={_.size(columns) || 100}>
        <div style={{ textAlign: "center", padding: 20, color: "#888" }}>
          {message || "Empty List"}
        </div>
      </td>
    </tr>
  );

  const settings = useMemo(() => {
    const classes = (...args) =>
      classNames(
        {
          "is-compact": compact,
          "is-bare": bare,
        },
        ...args,
      );

    return { compact, classes };
  }, [compact, bare]);

  const { classes } = settings;
  return (
    <MiniTableContext.Provider value={settings}>
      <div className={classes("mini-table")}>
        <div className={classes("mini-table--content")}>
          {title && <div className="mini-table--title">{title}</div>}
          <table className="mini-table--table">
            {header}
            <tbody className="mini-table--body">{contents}</tbody>
          </table>
        </div>
      </div>
    </MiniTableContext.Provider>
  );
};

MiniTable.HeaderRow = HeaderRow;
MiniTable.HeaderCell = HeaderCell;
MiniTable.Cell = Cell;
MiniTable.Row = Row;

export { Row, Cell, HeaderCell };

export default MiniTable;
