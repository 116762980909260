import _ from "lodash";
import React from "react";
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import "./AdminMenu.scss";

const AdminMenu = ({ label, disabled, children }) => {
  const _disabled = disabled || _.isEmpty(children);

  return (
    <Menu className="admin-menu">
      <MenuButton
        className="admin-menu--trigger-button admin-button is-default is-compact"
        disabled={_disabled}
      >
        {label || "Actions"}
      </MenuButton>

      <MenuList className="admin-menu--menu-list">{children}</MenuList>
    </Menu>
  );
};

AdminMenu.Item = MenuItem;
AdminMenu.Link = MenuLink;

export default AdminMenu;
