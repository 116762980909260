import React, { useState, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { useWrite, useAuthApi, useUser } from "worklete/hooks";
import { AdminInput, AdminLink } from "Sections/WorkleteAdmin/components";
import "./LoginController.scss";

const LoginController = () => {
  const { id: user_id, name: user_name, username: user_username } = useUser();

  const AuthApi = useAuthApi();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [send, sending, response] = useWrite(() => {
    return AuthApi.signIn({ username, password });
  });

  const signIn = () => send().catch(() => {});

  const singOut = () => {
    return AuthApi.signOut().catch(() => {});
  };

  const disabled = sending || !username || !password;

  return (
    <div className="login-controller">
      {response && <Redirect to="/worklete-admin/organizations"></Redirect>}

      <div className="login-controller--content">
        {user_id ? (
          <Fragment>
            {`You're logged in ${user_name} (${user_username})`}
            <AdminLink to="/worklete-admin/organizations">
              Continue to App
            </AdminLink>
            <AdminLink onClick={singOut}>Log Out</AdminLink>
          </Fragment>
        ) : (
          <Fragment>
            <label>Username</label>
            <AdminInput
              id="username"
              label="Username"
              value={username}
              onChange={setUsername}
            />
            <label>Password</label>
            <AdminInput
              label="Password"
              type="password"
              value={password}
              onChange={setPassword}
            />

            <button onClick={signIn} disabled={disabled}>
              Login
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default LoginController;
