import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./LayoutBar.css";

const { any } = PropTypes;

const Spacer = ({ width }) => (
  <span className="layout-bar--spacer" style={{ width }} />
);

Spacer.propTypes = { width: any };
Spacer.defaultProps = { width: 8 };
Spacer.displayName = "Bar.Spacer";

const Section = ({ children, gap = 0 }) => {
  const count = React.Children.count(children);

  const result = [];

  React.Children.forEach(children, (child, index) => {
    if (child) {
      result.push(child);
      if (index < count - 1 && child.type !== Spacer) {
        result.push(<Spacer key={`bar-spacer--${index}`} width={gap} />);
      }
    }
  });

  return <Fragment>{result}</Fragment>;
};

Section.displayName = "Bar.Section";
Section.propTypes = { gap: any };
Section.defaultProps = { gap: 8 };

const LayoutBar = ({ left, right, children, padding, style, ...rest }) => {
  const leftContent = left || React.Children.toArray(children)[0];
  const rightContent = right || React.Children.toArray(children)[1];
  return (
    <div className="layout-bar" style={{ padding, ...style }}>
      <div className="layout-bar--left">{leftContent}</div>
      <div className="layout-bar--right">{rightContent}</div>
    </div>
  );
};

LayoutBar.propTypes = { padding: any };
LayoutBar.defaultProps = { padding: 8 };
LayoutBar.Spacer = Spacer;
LayoutBar.Section = Section;

export { Spacer, Section };

export default LayoutBar;
