import React from "react";
import useSessionManagement from "./session/useSessionManagement";
import useSessionFormatting from "./session/useSessionFormatting";
import { SessionProviders } from "./session/SessionProviders";

import useOrgsManagement from "./orgs/useOrgsManagement";
import { OrgsProviders } from "./orgs/OrgsProviders";

import useCurrentOrgManagement from "./currentOrg/useCurrentOrgManagement";
import { CurrentOrgProviders } from "./currentOrg/CurrentOrgProviders";

import { useQueryParams } from "../QueryParamsManager/QueryParamsManager";

import useRouteOidRemoval from "./currentOrg/useRouteOidRemoval";
import { useSetBugsnagUser } from "App/System/BugsnagManager/bugsnagClient";

const AuthManagement = ({ children }) => {
  const { organization_id: route_oid } = useQueryParams();
  const [data, headers, sending, authApi, error] = useSessionManagement();
  const [session, user] = useSessionFormatting(headers, data, sending, error);
  const [orgs] = useOrgsManagement(session, user);
  const info = useCurrentOrgManagement(session, orgs, user, route_oid);
  const [org, orgApi, setOid, membership] = info;

  useRouteOidRemoval(org, route_oid);
  useSetBugsnagUser(user, headers);

  return (
    <SessionProviders session={session} user={user} authApi={authApi}>
      <OrgsProviders organizations={orgs}>
        <CurrentOrgProviders
          organization={org}
          membership={membership}
          organizationApi={orgApi}
          setOid={setOid}
        >
          {children}
        </CurrentOrgProviders>
      </OrgsProviders>
    </SessionProviders>
  );
};

export default AuthManagement;
