import _ from "lodash";
import React from "react";
import Json from "./Json";
import UUID from "../UUID/UUID";
import "./JsonValue.scss";

const getType = (value) => {
  return _.isObject(value)
    ? "object"
    : _.isBoolean(value)
    ? "boolean"
    : _.isString(value)
    ? "string"
    : _.isNumber(value)
    ? "number"
    : _.isNull(value)
    ? "null"
    : _.isUndefined(value)
    ? "undefined"
    : "unknown";
};

const JsonValue = ({ type, value }) => {
  const _type = type || getType(value);

  switch (_type) {
    case "object":
      return <Json collapsed={1} json={value} />;
    case "text":
      return <span className="json-value is-text">{value}</span>;
    case "id":
      return <UUID className="json-value is-id" id={value} />;
    case "string":
      return <span className="json-value is-string">{value}</span>;
    case "number":
      return <span className="json-value is-number">{value}</span>;
    case "date":
      return (
        <span className="json-value is-date">
          {value ? new Date(value).toLocaleString() : value}
        </span>
      );
    case "null":
      return <span className="json-value is-null">null</span>;
    case "undefined":
      return <span className="json-value is-undefined">undefined</span>;
    case "boolean":
      return (
        <span className="json-value is-boolean">
          {value ? "true" : "false"}
        </span>
      );
    default:
      return <span className="json-value is-unknown">{value}</span>;
  }
};

export default JsonValue;
