import _ from "lodash";
import React from "react";

const AdminSelect = ({ options, value, onChange }) => {
  const unexpected_value = !_.includes(options, value);

  return (
    <select value={value} onChange={(e) => onChange(e.target.value)}>
      {unexpected_value && (
        <option key={value} value={value}>
          {value}
        </option>
      )}
      {_.map(options, (option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default AdminSelect;
