import React from "react";
import "./AdminSimpleEditModalErrorBanner.scss";

import { errorMessage } from "worklete/format";

const AdminSimpleEditModalBanner = ({ error, onClear }) => {
  const message = errorMessage(error);

  const status = error && error.status;

  return (
    <div className="admin-simple-edit-modal-error-banner">
      <span className="admin-simple-edit-modal-error-banner--left">
        {status && (
          <span className="admin-simple-edit-modal-error-banner--status">
            {status}
          </span>
        )}
        <span className="admin-simple-edit-modal-error-banner--message">
          {message}
        </span>
      </span>

      <span
        className="admin-simple-edit-modal-error-banner--clear-action"
        onClick={onClear}
      >
        Clear
      </span>
    </div>
  );
};

export default AdminSimpleEditModalBanner;
