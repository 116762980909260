import React from "react";
import { NavLink } from "react-router-dom";
import "./VerticalNav.scss";

const VerticalNavItem = ({ title, subtitle, children, ...rest }) => {
  return (
    <NavLink
      {...rest}
      className="vertical-nav--link"
      activeClassName="is-active"
    >
      <div className="vertical-nav--link-title">{title || children}</div>
      {subtitle && (
        <span className="vertical-nav--link-subtitle">{subtitle}</span>
      )}
    </NavLink>
  );
};
const VerticalNavHeading = ({ children }) => {
  return <div className="vertical-nav--heading">{children}</div>;
};

const VerticalNavDivider = () => {
  return <div className="vertical-nav--divider"></div>;
};

const VerticalNav = ({ children }) => {
  return <div className="vertical-nav">{children}</div>;
};

VerticalNav.Item = VerticalNavItem;
VerticalNav.Heading = VerticalNavHeading;
VerticalNav.Divider = VerticalNavDivider;

export default VerticalNav;
