import React, { createContext, useContext } from "react";
import Message from "../../Message/Message";
import Spinner from "../Spinner";
import "./LoadingContainer.scss";
import { errorMessage } from "worklete/format";

const AdminLoadingContext = createContext(false);

const ErrorMessage = ({ error, message, ...rest }) => {
  const msg = message || errorMessage(error);

  return <Message icon="error" message={msg} {...rest} />;
};

const LoadingContainer = ({
  children,
  loading,
  loadingMessage,
  error,
  ...rest
}) => {
  const parent_is_loading = useContext(AdminLoadingContext);

  const show_loading = loading && !parent_is_loading;

  return (
    <div className="admin-loading-container">
      <div className="admin-loading-container--content">
        <AdminLoadingContext.Provider value={loading || parent_is_loading}>
          {children}
        </AdminLoadingContext.Provider>
      </div>
      {error ? (
        <div className="admin-loading-container--shade">
          <ErrorMessage error={error} {...rest} />;
        </div>
      ) : show_loading ? (
        <div className="admin-loading-container--shade">
          <Message
            icon={<Spinner />}
            message={loadingMessage || "Loading..."}
            {...rest}
          />
        </div>
      ) : null}
    </div>
  );
};

export default LoadingContainer;
