import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { useUser } from "worklete/hooks";
import { WorkleteAdmin } from "Sections/Sections";

const AuthenticatedRoutes = () => {
  const { worklete_employee } = useUser();

  return (
    <Switch>
      <Route>
        {worklete_employee ? <WorkleteAdmin /> : <Redirect to="/auth/logout" />}
      </Route>

      <Route>
        <Redirect to="/worklete-admin" />
      </Route>
    </Switch>
  );
};

export default AuthenticatedRoutes;
