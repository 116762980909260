import React from "react";
import "./InlineError.scss";

const InlineError = ({ message }) => {
  return (
    <div className="inline-error--message">
      <div className="inline-error-message--content">{message}</div>
    </div>
  );
};

export default InlineError;
