import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import "./AdminTab.scss";

const AdminTabs = ({ compact, ...rest }) => {
  return <div className="admin-tabs" {...rest} />;
};

const AdminTab = ({
  to,
  children,
  isActiveTab,
  disabled,
  compact,
  ...rest
}) => {
  const inner = (
    <span
      className={classNames("admin-tab--content", {
        "is-compact": compact,
        "is-disabled": disabled,
      })}
    >
      {children || to}
    </span>
  );

  if (to) {
    return (
      <NavLink
        to={to}
        disabled={disabled}
        className={classNames("admin-tab", {
          "is-compact": compact,
          "is-disabled": disabled,
        })}
        activeClassName="is-active"
        {...rest}
      >
        {inner}
      </NavLink>
    );
  } else {
    return (
      <button
        disabled={disabled}
        className={classNames("admin-tab", {
          "is-compact": compact,
          "is-disabled": disabled,
          "is-active": isActiveTab,
        })}
        {...rest}
      >
        {inner}
      </button>
    );
  }
};

export { AdminTabs };

export default AdminTab;
