import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function Spinner({ size = 24, style, ...rest }) {
  return (
    <div>
      <CircularProgress
        style={{ fontSize: size, ...style }}
        color="secondary"
        {...rest}
      />
    </div>
  );
}

export default Spinner;
