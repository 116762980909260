import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    "&:hover $notchedOutline": {
      borderColor: theme.palette.secondary.main,
    },
    "&$focused $notchedOutline": {
      borderColor: theme.palette.secondary.medium,
    },
  },
  notchedOutline: {}, //Intentionally given this name and left empty to allow nested JSS reference
  focused: {}, //Intentionally given this name and left empty to allow nested JSS reference
});

const CourseBuilderInput = ({ onChange, classes, ...rest }) => {
  return (
    <TextField
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      InputProps={{ classes }}
      fullWidth
      {...rest}
    />
  );
};

export default withStyles(styles)(CourseBuilderInput);
