import React from "react";
import { Link } from "react-router-dom";
import { useSession } from "App/Auth/session/SessionProviders";
import "./EnvBadge.scss";
import { api_origin } from "App/System/server/server";

const trueOrFalse = (val) => val === true || val === "true";

const badge_color = process.env.REACT_APP_ENV_COLOR || "red";

const show_badge = trueOrFalse(process.env.REACT_APP_SHOW_ENV_BADGE);

const EnvBadge = () => {
  const session = useSession();
  const { sending, signed_in, error_message } = session;

  if (!show_badge) {
    return null;
  }

  const message =
    error_message || (sending ? "Signing In" : !signed_in ? "Signed Out" : "");

  return (
    <Link
      style={{ backgroundColor: badge_color }}
      className="env-badge"
      to="/debug"
    >
      {message && `${message} - `}
      {api_origin}
    </Link>
  );
};

export default EnvBadge;
