import React from "react";
import str from "underscore.string";
import "./AdminSimpleEditModalItem.scss";
import { useAdminSimpleModal } from "./AdminSimpleEditModalContext";
import AdminSelect from "../AdminSelect/AdminSelect";

const humanize = (key) => str.titleize(str.humanize(key));

const AdminSimpleEditModalSelect = (props) => {
  const { propertyKey, label, note, options } = props;

  const { value, onChange } = useAdminSimpleModal(propertyKey);

  const _label = label || humanize(propertyKey);

  return (
    <div className="admin-simple-edit-item">
      <label className="admin-simple-edit-item--label">{_label}</label>
      <AdminSelect options={options} value={value} onChange={onChange} />
      {note && <span className="admin-simple-edit-item--note">{note}</span>}
    </div>
  );
};

export default AdminSimpleEditModalSelect;
