import _ from "lodash";
import React, { useState, useEffect } from "react";
import AdminModal from "../AdminModal/AdminModal";
import { wrapWithButton, wrapWithLink } from "./wrapWith";
import { useWrite, usePrevious } from "worklete/hooks";
import { AdminSimpleEditModalProvider } from "./AdminSimpleEditModalContext";
import AdminSimpleEditModalBoolean from "./AdminSimpleEditModalBoolean";
import AdminSimpleEditModalItem from "./AdminSimpleEditModalItem";
import AdminSimpleEditModalSelect from "./AdminSimpleEditModalSelect";
import AdminSimpleEditModalErrorBanner from "./AdminSimpleEditModalErrorBanner";

const AdminSimpleEditModal = (props) => {
  const {
    is_open,
    children,
    initialValue,
    properties,
    submitDisabledFn,
    submitLabel,
    onSubmit,
    triggerLabel,
    title,
    onClose,
    ...rest
  } = props;

  const [state, setState] = useState(initialValue);

  const [send, sending, , error, clear] = useWrite((value) => {
    return onSubmit(value);
  });

  const prevState = usePrevious(state);

  useEffect(() => {
    if (error && !_.isEqual(prevState, state)) clear();
  }, [prevState, state, error, clear]);

  const handleSubmit = async () => {
    await send(state);
    onClose();
  };

  const reset = () => {
    setState(initialValue);
    clear();
  };

  const previous_open = usePrevious(is_open);

  useEffect(() => {
    if (previous_open && !is_open) reset();
  }, [previous_open, is_open]); // eslint-disable-line

  const _title = title || triggerLabel || "Edit";

  const _submitLabel = sending ? "Sending..." : submitLabel || "Submit";

  return (
    <AdminModal
      {...rest}
      onClose={onClose}
      title={_title}
      onSubmit={handleSubmit}
      submitDisabled={false}
      submitLabel={_submitLabel}
      is_open={is_open}
    >
      {error && (
        <AdminSimpleEditModalErrorBanner error={error} onClear={clear} />
      )}
      <AdminSimpleEditModalProvider value={state} onChange={setState}>
        {children}
      </AdminSimpleEditModalProvider>
    </AdminModal>
  );
};

AdminSimpleEditModal.Item = AdminSimpleEditModalItem;
AdminSimpleEditModal.Select = AdminSimpleEditModalSelect;
AdminSimpleEditModal.Boolean = AdminSimpleEditModalBoolean;
AdminSimpleEditModal.withButtonTrigger = wrapWithButton(AdminSimpleEditModal);
AdminSimpleEditModal.withLinkTrigger = wrapWithLink(AdminSimpleEditModal);

export default AdminSimpleEditModal;
