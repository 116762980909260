import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Pagination.scss";

const { object, func, bool, string } = PropTypes;

const left_chevron = "\u2039";
const right_chevron = "\u203A";
const double_right_chevron = "\u00BB";
const double_left_chevron = "\u00AB";

const propTypes = {
  meta: object,
  onChange: func,
  loading: bool,
  label: string,
};

const defaultProps = {
  onPageChange: _.noop,
  label: "Items",
};

const singular = (string = "") =>
  string.slice(-1) === "s" ? string.slice(0, -1) : string;

const Pagination = ({ meta = {}, onPageChange, loading, label, ...rest }) => {
  const info = meta.page || {};

  const page = info.current_page || 1;
  const target_page = rest.page || page;
  const page_size = info.page_size;
  const item_count = info.total_count || 0;
  const page_count = info.total_pages;

  const left = page_size * (target_page - 1) + 1;
  const right = Math.min(page_size * target_page, item_count);

  const working = loading || target_page !== page;

  const display =
    item_count === 0 ? (
      <span>
        <b>{0}</b> {label}
      </span>
    ) : item_count === 1 ? (
      <span>
        <b>{1}</b> {singular(label)}
      </span>
    ) : page_count < 2 ? (
      <span>
        <b>{item_count}</b> {label}
      </span>
    ) : (
      <span>
        <b>{left}</b> - <b>{right}</b> of <b>{item_count}</b> {label}
      </span>
    );

  const multiple_pages = page_count >= 2;

  return (
    <span className="admin-pagination">
      <span className="admin-pagination--count">{display}</span>
      <span className="admin-pagination--buttons">
        <button
          className={classNames("admin-pagination--button", {
            "is-working": working,
          })}
          onClick={() => onPageChange(1)}
          disabled={!multiple_pages || target_page <= 1}
        >
          {double_left_chevron}
        </button>
        <button
          className={classNames("admin-pagination--button", {
            "is-working": working,
          })}
          onClick={() => onPageChange(target_page - 1)}
          disabled={!multiple_pages || target_page <= 1}
        >
          {left_chevron}
        </button>
        <button
          className={classNames("admin-pagination--button", {
            "is-working": working,
          })}
          onClick={() => onPageChange(target_page + 1)}
          disabled={!multiple_pages || target_page >= page_count}
        >
          {right_chevron}
        </button>
        <button
          className={classNames("admin-pagination--button is-last", {
            "is-working": working,
          })}
          onClick={() => onPageChange(page_count)}
          disabled={!multiple_pages || target_page === page_count}
        >
          {double_right_chevron}
        </button>
      </span>
    </span>
  );
};

Pagination.propTypes = propTypes;

Pagination.defaultProps = defaultProps;

export default Pagination;
