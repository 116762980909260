import React from "react";
import "./AdminTitle.scss";

const AdminH1 = ({ children }) => <h1 className="admin-h1">{children}</h1>;

const AdminH2 = ({ children }) => <h2 className="admin-h2">{children}</h2>;

const AdminH3 = ({ children }) => <h3 className="admin-h3">{children}</h3>;

const AdminTitle = ({ children, title }) => (
  <span className="admin-title">{title || children || " "}</span>
);

export { AdminH1, AdminH2, AdminH3, AdminTitle };
